
/**
 * 変数
*****************************************************/
var lastScrollPos = 0;
const html = document.querySelector('html');
const userAgent = navigator.userAgent;
const mq = window.matchMedia("(min-width: 767px)");
let modalCloseOffset = 0;

/**
 * 処理
*****************************************************/
window.onload = function () {
}
window.addEventListener("load", function () {
	setVh();
	stickySideNav();
});
document.addEventListener("DOMContentLoaded", function () {
	current_location();
	showDropdownMenu();
	showSearchDropdownMenu();
	showDropdownProducts();
	showSpNav();
	modal('.js-global-modal-open', '.js-global-modal', '.js-global-modal-close');
	modal('.js-filter-modal-open', '.js-filter-modal', '.js-filter-modal-close');
	modalSerial('.js-installation-room-modal-open', '.js-installation-room-modal', '.js-installation-room-modal-close');
	modalSerial('.js-movie-modal-open', '.js-movie-modal', '.js-movie-modal-close');
	pageTop();
	mainvSliderTop();
	seminarSliderTop();
	techSliderTop();
	moreBtn();
	changeTab('.js-tab li', '.js-tab-target');
	changeNewsTab();
	checkedRefleshBtn();
	productsLowerMainvSlider();
	openIndex();
	openIndexLower();
	openAccordion();
	confirmModal();
	selectbox();
	// sdsForm();
	countUpNum();
	pinMap();
	gallery();
	SlideToggle();
	productMainvPadding();
	setModal();
	openContents();
});
document.addEventListener("scroll", function () {
	headerFixed();
});
window.addEventListener("resize", function () {
	setVh();
});
mq.addEventListener("change", function() {
	showDropdownMenu();
	showDropdownProducts();
	modal('.js-filter-modal-open', '.js-filter-modal', '.js-filter-modal-close');
	openIndex();
	openIndexLower();
	changeNewsTab();
	stickySideNav();
})


/**
* 関数
*****************************************************/
//PC判定
function isPc() {
	if (userAgent.indexOf('iPhone') > 0 || userAgent.indexOf('Android') > 0 && userAgent.indexOf('Mobile') > 0) {
		return false;
	} else if (userAgent.indexOf('iPad') > 0 || userAgent.indexOf('Android') > 0) {
		return false;
	} else {
		return true;
	}
}
//スマホ判定
function isMobile() {
	if (window.matchMedia && window.matchMedia('screen and (max-width: 767px)').matches) {
		return true;
	} else {
		return false;
	}
}
// Activeクラス保持判定
function isActive(element) {
	return element.classList.contains('active');
}
// 配列に変換
function convertArray(node) {
	const convertResult = Array.prototype.slice.call(node)
	return convertResult;
}

// 汎用ページ内リンク
var Ease = {
	easeInOut: function easeInOut(t) {
		return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
	}
};
var duration = 500;

window.addEventListener('DOMContentLoaded', function () {
	var headerHeight=0;
	if(!location.href.match("/sustainability")) {
		headerHeight=document.querySelector('.l-header').clientHeight;
	} else {
		//サステのヘッダーの高さ
		headerHeight=80;
	}
	var hash=location.hash.replace('#','');
	if(hash){
		anchorAnimate(hash)	
		window.addEventListener('load', function () {
			anchorAnimate(hash)	
		});
	}
	var smoothScrollTriggers = convertArray(document.querySelectorAll('a[href^="#"]'));
	smoothScrollTriggers.forEach(function (smoothScrollTrigger) {
		smoothScrollTrigger.addEventListener('click', function (e) {
			var href = smoothScrollTrigger.getAttribute('href');
			e.preventDefault();
			e.stopPropagation();
			anchorAnimate(href);

		});
	});
	function anchorAnimate(href){
		var currentPostion = document.documentElement.scrollTop || document.body.scrollTop;
		var targetElement = document.getElementById(href.replace('#', ''));
		
		if (targetElement) {
			var targetPosition;
			// 製品情報詳細のスクロール制御
			if (targetElement.classList.contains('scroll-point')) {
				if (isMobile()) {
					targetPosition = window.pageYOffset + targetElement.getBoundingClientRect().top - headerHeight - 70;
				} else if (window.matchMedia && window.matchMedia('screen and (max-width: 1001px)').matches) {
					targetPosition = window.pageYOffset + targetElement.getBoundingClientRect().top - headerHeight - 100;
				} else {
					targetPosition = window.pageYOffset + targetElement.getBoundingClientRect().top - headerHeight - 10;
				}
				
			} else {
				targetPosition = window.pageYOffset + targetElement.getBoundingClientRect().top - headerHeight;
			}
			var startTime = performance.now();

			var loop = function loop(nowTime) {
				var time = nowTime - startTime;
				var normalizedTime = time / duration;

				if (normalizedTime < 1) {
					window.scrollTo(0, currentPostion + (targetPosition - currentPostion) * Ease.easeInOut(normalizedTime));
					requestAnimationFrame(loop);
				} else {
					window.scrollTo(0, targetPosition);
				}
			};

			requestAnimationFrame(loop);
		}
	}
});

// 100vhの調整
function setVh() {
	const vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
}

// グロナビ 現在地
function current_location() {
	var map = [
		{ dir: "/products", selector: "nav_products" },
		{ dir: "/solutions", selector: "nav_solutions" },
		{ dir: "/service_support", selector: "nav_service_support" },
		{ dir: "/events_seminar", selector: "nav_events_seminar" },
		{ dir: "/corporate", selector: "nav_corporate" },
		{ dir: "/corporate/ir", selector: "nav_ir" },
		{ dir: "/sustainability", selector: "nav_sustainability" },
	];

	for (var i = 0; i < map.length; i++) {
		if (location.href.match(map[i].dir)) {
			document.getElementById(map[i].selector).classList.add("current");
			if(map[i].dir == "/corporate/ir") { //IRのみ会社情報配下だがIRにカレントつける
				document.getElementById("nav_corporate").classList.remove("current");
				document.getElementById("nav_ir").classList.add("current");
			}
		} 
	}
}

/*スクロールしたらヘッダーに下線追加
* 使用場所：ヘッダー
*/
function headerFixed() {
	var header = jQuery('.l-header');
	var scroll = jQuery('.js-scroll');
	if(jQuery(this).scrollTop() > 1) {
		header.addClass('is-fixed');
		if($(this).scrollTop() < lastScrollPos ){
			header.removeClass('is-fixed--scroll');
			scroll.removeClass('scrollheader');
		}else{
			header.addClass('is-fixed--scroll');
			scroll.addClass('scrollheader');
		}
		lastScrollPos = $(this).scrollTop();
	} else{
	  header.removeClass('is-fixed');
	}
}

/*ドロップダウンメニュー
* 使用場所：ヘッダー
*/
function showDropdownMenu() {
	var dropdown = document.querySelectorAll(".js-dropdown");
	var dropdownArr = convertArray(dropdown);
	var body = document.body;

	dropdownArr.forEach(function(e) {
		jQuery(e).off();
		if(!mq.matches) {
			jQuery(e).on("click", function(event) {
				var targetHeight = e.querySelector('.js-dropdown-menu').querySelector('.js-dropdown-menu .l-dropdown__inner').clientHeight;
				var target = e.querySelector('.js-dropdown-menu');

				if(!isActive(e)) {
					// ほかの開いているメニューを閉じる
					// dropdownArr.forEach(function(e) {
                    //     e.classList.remove('active');
                    //     e.querySelector('.js-dropdown-menu').style.height = 0 + "px";
					// });
					// クリックしたメニューを開く
					e.classList.add('active');
					target.style.height = targetHeight + "px";
				} else {
					e.classList.remove('active');
					target.style.height = 0 + "px";
				}
			});
		} else {
			dropdownArr.forEach(function(e) {
				e.classList.remove('active');
				e.querySelector('.js-dropdown-menu').style.height = 0 + "px";
			});

			jQuery(e).on({
				'mouseenter': function() {
					sethover = setTimeout(function(){
						var targetHeight = e.querySelector('.js-dropdown-menu').querySelector('.js-dropdown-menu .l-dropdown__inner').clientHeight;
						var target = e.querySelector('.js-dropdown-menu');
						var scroll = e.querySelector('.js-scroll');

						e.classList.add('active');
						target.classList.add('active');
						target.style.height = targetHeight + "px";
						body.classList.add('cover');
						target.scrollTop = 0;

						// スクロールボタン制御
						// 現在の縦スクロール位置
						var scrollPosition = 0;
						// スクロール要素の高さ
						var scrollHeight = target.scrollHeight;
						// スクロール領域の高さ
						var targetSclollHeight = target.clientHeight;
						// スクロールできる量
						var maxScroll = scrollHeight - targetSclollHeight;
						setTimeout(() => { //描画されてから図る
							targetSclollHeight = target.clientHeight;
							maxScroll = scrollHeight - targetSclollHeight - 20;
							
							if(maxScroll > 1 && isActive(e)) {
								$(scroll).stop().fadeIn();
							}

							if(isActive(e)) {
								target.addEventListener('scroll', function() {
									// スクロール位置
									scrollPosition = target.scrollTop;
									if(scrollPosition > maxScroll) {
										$(scroll).stop().fadeOut();
									} 
									else if (scrollPosition < maxScroll) {
										$(scroll).stop().fadeIn();
									}
									return false;
								})
							} else {
								$(scroll).stop().fadeOut();
							}
						}, 510);
					},300);
				},
				'mouseleave': function() {
					var target = e.querySelector('.js-dropdown-menu');
					var scroll = e.querySelector('.js-scroll');
					e.classList.remove('active');
					target.classList.remove('active');
					target.style.height = 0 + "px";
					body.classList.remove('cover');
					$(scroll).hide();

					if(!target.classList.contains('active')) {
						$(scroll).hide();
					};
					
					clearTimeout(sethover);
				}
			})
		}
	});


	// タブ切り替え
	changeHeaderTab('.js-tab-header li', '.js-tab-header-target');

	function changeHeaderTab(tab, tabContent) {
		if (document.querySelectorAll(tab).length == 0) { return false }
	
		const tabBtn = document.querySelectorAll(tab);
		const tabBtnArr = convertArray(tabBtn);
		const tabTarget = document.querySelectorAll(tabContent);
		const tabTargetArr = convertArray(tabTarget);
		var headerTab = convertArray(document.querySelectorAll('.js-dropdown .js-tab-header li'));
		var wordLink = document.getElementById('js-wordLink');
		var medicalLink;
		if (document.querySelectorAll('.js-onlyshow-medical').length != 0) { 
			medicalLink = document.querySelectorAll('.js-onlyshow-medical');
		}

		tabBtnArr.forEach(function(e) {
			e.addEventListener('click', function() {
				// activeクラスすべて外す
				tabBtnArr.forEach(function(e) {
					e.classList.remove('active');
				});
				tabTargetArr.forEach(function(e) {
					e.classList.remove('active');
				});
				// クリックした要素と対応するコンテンツにactiveクラス付ける
				this.classList.add('active');
				const index = tabBtnArr.indexOf(this);
				tabTargetArr[index].classList.add('active');
		
				// ヘッダーの用語集、医用機器だけのメニュー表示非表示切り替え
				if (e !== headerTab[0]) {
					if (medicalLink !== undefined) {
						if (e === headerTab[3]) {
							medicalLink.forEach(function(e) {	
								$(e).fadeIn();
							})
						} else {
							medicalLink.forEach(function(e) {	
								$(e).fadeOut();
							})
						}
					}

					wordLink.style.display = 'none';
					$('#js-tab-scroll').fadeOut();
				} else {
					wordLink.style.display = 'flex';
				}
				
				// タブ切り替えるごとに高さ調整する
				var target = e.closest('.js-dropdown-menu');
				var currentHeight = e.closest('.l-dropdown__inner').clientHeight;
				target.style.height = currentHeight + "px";
			});
		});
	
	}
	
}

/*サイト内検索ドロップダウンメニュー
* 使用場所：ヘッダー
*/
function showSearchDropdownMenu() {
	if (document.querySelectorAll('.js-header-search').length == 0) { return false };
	var searchBtn = document.querySelector('.js-header-search');
	var searchMenu = document.querySelector('.js-header-search-menu');
    var searchCloseBtn = document.querySelector('.js-header-search-close');
	var body = document.body;
    searchBtn.addEventListener('click', function(e) {
        e.preventDefault();
        var searchMenuHeight = searchMenu.querySelector('.l-search__inner').clientHeight;
        if(!isActive(searchBtn)) {
            searchBtn.classList.add('active');
			searchMenu.classList.add('active');
            searchMenu.style.height = searchMenuHeight + "px";
			body.classList.add('cover');
        } else {
            searchBtn.classList.remove('active');
			searchMenu.classList.remove('active');
            searchMenu.style.height = 0 + "px";
			body.classList.remove('cover');
        }
    })
    searchCloseBtn.addEventListener('click', function(e) {
        e.preventDefault();
        searchBtn.classList.remove('active');
		searchMenu.classList.remove('active');
        searchMenu.style.height = 0 + "px";
		body.classList.remove('cover');
    })
}


/*SPハンバーガーメニュー
* 使用場所：ヘッダー
*/
function showSpNav() {
	if (!document.getElementById('js-sp-menu')) { return false}
	const btn = document.getElementById('js-sp-menu');
	const target = document.querySelector('.js-sp-menu-target');
	const body = document.body;

	btn.addEventListener("click", function () {
		if(!isActive(this)) {
			this.classList.add('active');
			target.classList.add('active');
			body.classList.add('cover');
		} else {
			this.classList.remove('active');
			target.classList.remove('active');
			body.classList.remove('cover');
		}
	});
}

/*ドロップダウンメニュー
* 使用場所：フッター製品情報
*/
function showDropdownProducts() {
    if(document.querySelectorAll('.js-footer-dropdown').length == 0) { return false };
    var openBtn = document.querySelector('.js-footer-dropdown');
	var target = document.querySelector('.js-footer-dropdown-target');
    jQuery(openBtn).off();
    if(!mq.matches) {
        target.style.height = 0 + "px";
        jQuery(openBtn).on("click", function(event) {
            event.preventDefault();
            var targetHeight = target.querySelector('.l-footer__upper__products__inner').clientHeight;
            if(!isActive(openBtn)) {
                openBtn.classList.add('active');
                target.style.height = targetHeight + "px";
            } else {
                openBtn.classList.remove('active');
                target.style.height = 0 + "px";
            }
        });
    } else {
        target.style.height = 'inherit';
    }
}

/*特定モーダル
* 使用場所：Global
*/
function modal(open, target, close) {
	if (document.querySelectorAll(open).length == 0) { return false }

	const modalTrigerArr = convertArray(document.querySelectorAll(open));
	const modal = document.querySelector(target);
	const closeArr = convertArray(document.querySelectorAll(close));
	const body = document.body;
	let offset = 0;

	modalTrigerArr.forEach(function (item, i) {
        modalTrigerArr[i].addEventListener("click", function (e) {
            e.preventDefault();

            offset = window.pageYOffset;

            modal.classList.add("active");
            body.classList.add("is-noscroll-ip");
            body.style.top = (- offset) + 'px';
        });
    });

    closeArr.forEach(function (item, i) {
        closeArr[i].addEventListener("click", function (e) {
            e.preventDefault();
			modalClose();
        });
    });

	if(mq.matches) {
		modalClose()
	}

	function modalClose() {
		modal.classList.remove("active");
		body.classList.remove("is-noscroll-ip");
		body.style.top = '';
		window.scrollTo(0,offset);
	}
}

/*連続モーダル
* 使用場所：設置環境対策
*/
function modalSerial(open, target, close) {
	if (document.querySelectorAll(open).length == 0) { return false }

	const modalTrigerArr = convertArray(document.querySelectorAll(open));
	const modal = convertArray(document.querySelectorAll(target));
	const modalContent = convertArray(document.querySelectorAll('.m-modal__contents'))
	const closeArr = convertArray(document.querySelectorAll(close));
	const body = document.body;
	let offset = 0;

	modalTrigerArr.forEach(function (item, i) {
        modalTrigerArr[i].addEventListener("click", function (e) {
            e.preventDefault();
            offset = window.pageYOffset;

            modal[i].classList.add("active");
            body.classList.add("is-noscroll-ip");
            body.style.top = (- offset) + 'px';
        });
    });

	modalContent.forEach(function (item, i) {
		item.addEventListener("click", function (e) {
			e.stopPropagation();
		});
	});

	closeArr.forEach(function (item, i) {
      closeArr[i].addEventListener("click", function (e) {
          e.preventDefault();
          modalClose();
      });
  	});

	function modalClose() {
		modal.forEach(function (item, i) {
			item.classList.remove("active");
		});
		body.classList.remove("is-noscroll-ip");
		body.style.top = '';
		window.scrollTo(0,offset);

		if(open == '.js-movie-modal-open') {
			videoControl("pauseVideo");
		}
	}
	function videoControl(action) {
		const iframe = convertArray(document.querySelectorAll('.js-stop-movie'));
		iframe.forEach(function(e) {
			var target = e.contentWindow;
			target.postMessage(
			  '{"event":"command","func":"' + action + '","args":""}',
			  "*"
			);
		})
	}
}

/*ページトップボタン
* 使用場所：全ページ共通
*/
function pageTop() {
	if (document.getElementById('page-top')) {
		var pageTopBtn = document.getElementById('page-top');

		jQuery(window).on('load scroll', function(){
			if(jQuery(this).scrollTop() > 100) {
			  pageTopBtn.classList.add('active');
			}else{
			  pageTopBtn.classList.remove('active');
			}
		});

		pageTopBtn.addEventListener("click", function (e) {
			e.preventDefault();
            window.scroll({
                top: 0,
                behavior: 'smooth'
            })
		}, false)
	}
}

function mainvSliderTop() {
	if (document.querySelectorAll('.js-top-mainv').length == 0) { return false }
	var mainvSlider = jQuery('.js-top-mainv');

	var durTime = 3000;
	var spdTime = 1500;
	var svgSetting = {
		strokeWidth: 2,
		duration: durTime,
		color: "#fff",
		trailColor: "rgba(255, 255, 255, 0.3)",
		trailWidth: 1,
		svgStyle: {
			width: "100%",
			height: "100%"
		}
	};

	mainvSlider.on("init", function (event, slick) {
		if (jQuery('.slick-dots li').length === 0) {
		return false;
		}

		new ProgressBar.Circle(".slick-dots li", svgSetting).animate(1.0);
		jQuery(".slick-dots li").find("svg").fadeIn(spdTime);
	});

	mainvSlider.on("beforeChange", function (event, slick, currentSlide) {
		if (jQuery('.slick-dots li').length === 0) {
			return false;
		}

		var num = currentSlide + 1;
		jQuery(".slick-dots li:nth-child(" + num + ")").find("svg").fadeOut(spdTime, function () {
			$(this).remove();
		});
	});

	mainvSlider.on("afterChange", function (event, slick, currentSlide) {
		if (jQuery('.slick-dots li').length === 0) {
			return false;
		}

		var num = currentSlide + 1;
		new ProgressBar.Circle(".slick-dots li:nth-child(" + num + ")", svgSetting).animate(1.0);
		jQuery(".slick-dots li:nth-child(" + num + ")").find("svg").fadeIn(spdTime);
	});

	mainvSlider.not('.slick-initialized').slick({
		autoplay: true,
		// fade:true,
		autoplaySpeed: durTime,
		speed: spdTime,
		arrows: false,
		appendDots: ".p-top__mainv__control",
		dots: true,
		pauseOnFocus: false,
		pauseOnHover: false,
		pauseOnDotsHover: false,
	});
}


function productsLowerMainvSlider() {
	if (document.querySelectorAll('.js-products-lower-mainv').length == 0) { return false }
	var mainvSlider = jQuery('.js-products-lower-mainv');

	var durTime = 3000;
	var spdTime = 1500;
	var svgSetting = {
		strokeWidth: 2,
		duration: durTime,
		color: "#555555",
		trailColor: "rgba(85, 85, 85, 0.3)",
		trailWidth: 1,
		svgStyle: {
			width: "100%",
			height: "100%"
		}
	};

	mainvSlider.on("init", function (event, slick) {
		if (jQuery('.slick-dots li').length === 0) {
		return false;
		}

		new ProgressBar.Circle(".slick-dots li", svgSetting).animate(1.0);
		jQuery(".slick-dots li").find("svg").fadeIn(spdTime);
	});

	mainvSlider.on("beforeChange", function (event, slick, currentSlide) {
		if (jQuery('.slick-dots li').length === 0) {
			return false;
		}

		var num = currentSlide + 1;
		jQuery(".slick-dots li:nth-child(" + num + ")").find("svg").fadeOut(spdTime, function () {
			$(this).remove();
		});
	});

	mainvSlider.on("afterChange", function (event, slick, currentSlide) {
		if (jQuery('.slick-dots li').length === 0) {
			return false;
		}

		var num = currentSlide + 1;
		new ProgressBar.Circle(".slick-dots li:nth-child(" + num + ")", svgSetting).animate(1.0);
		jQuery(".slick-dots li:nth-child(" + num + ")").find("svg").fadeIn(spdTime);
	});

	mainvSlider.not('.slick-initialized').slick({
		autoplay: true,
		loop: true,
		infinite: true,
		autoplaySpeed: durTime,
		speed: spdTime,
		arrows: true,
		appendDots: ".p-products-lower__mainv__control",
		dots: true,
		pauseOnFocus: false,
		pauseOnHover: false,
		pauseOnDotsHover: false,
		responsive: [
			{
			  breakpoint: 768,
			  settings: {
				arrows: false,
			  },
			},
		],
	});
}

function seminarSliderTop() {
	if (document.querySelectorAll('.js-top-seminar-slider').length == 0) { return false }

	var topSeminar = jQuery('.js-top-seminar-slider');
	var slideLength = jQuery('.js-top-seminar-slider .p-top__seminar__list__item').length;
	var showValPc = 3.2;
	var showValPcmin = 2.2;
	var showValSp = 1.2;

	topSeminar.on('init setPosition', function (event, slick) {
		if (slideLength <= showValPc && slideLength <= showValPcmin) {jQuery('.js-top-seminar-slider.slick-slider').addClass('is-few');}
		if (slideLength <= showValPc) { jQuery('.js-top-seminar-slider.slick-slider').addClass('is-few-pcmin');}
		if (slideLength <= showValSp) { jQuery('.js-top-seminar-slider.slick-slider').addClass('is-few-sp'); }
	});

	topSeminar.not('.slick-initialized').slick({
		infinite: false,
		loop: false,
		initialSlide: 0,
		slidesToShow: showValPc,
		variableWidth: true,
		pauseOnFocus: false,
		pauseOnHover: false,
		draggable: true,
		responsive: [
			{
				breakpoint: 1600,
				settings: {
					slidesToShow: showValPc,
					variableWidth: false,
				}
			},
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: showValPcmin,
					variableWidth: false,
				}
			},
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					slidesToShow: showValSp,
					variableWidth: true,
				}
			},
		]
	});
}


function techSliderTop() {
	if (document.querySelectorAll('.js-top-tech-slider').length == 0) { return false }

	var topTechSlider = jQuery('.js-top-tech-slider');
	var showValPc = 4.2;
	var showValPcmin = 3.2;
	var showValSp = 1.2;
	var setting = {
		infinite: false,
		loop: false,
		initialSlide: 0,
		slidesToShow: showValPc,
		variableWidth: true,
		pauseOnFocus: false,
		pauseOnHover: false,
		draggable: true,
		responsive: [
			{
				breakpoint: 1600,
				settings: {
					slidesToShow: showValPc,
					variableWidth: false,
				}
			},
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: showValPcmin,
					variableWidth: false,
				}
			},
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					slidesToShow: showValSp,
					variableWidth: true,
				}
			},
		]
	}
	function addClass() {
		var slideLength = jQuery('.js-top-tech-slider .p-top__technology__list__item').length;
		if (slideLength <= showValPc && slideLength <= showValPcmin) {jQuery('.js-top-tech-slider.slick-slider').addClass('is-few');}
		if (slideLength <= showValPc) { jQuery('.js-top-tech-slider.slick-slider').addClass('is-few-pcmin');}
		if (slideLength <= showValSp) { jQuery('.js-top-tech-slider.slick-slider').addClass('is-few-sp'); }
	}

	// 初期実行
	topTechSlider.on('init setPosition', function (event, slick) {
		addClass()
	});
	topTechSlider.not('.slick-initialized').slick(setting);

	// フィルター
	var filterBtn = jQuery('.js-btn .p-top__technology__index__item');
	filterBtn.each(function(index, element){
		if(index === 0) {
			jQuery(element).on('click', function() {
				init();
				$(this).addClass('active');
				// topTechSlider.not('.slick-initialized').slick(setting);
				addClass();
			});
		} else {
			jQuery(element).on('click', function() {
				var name = '.type0' + index;
				init();
				$(this).addClass('active');
				topTechSlider.slick('slickFilter', name, function(){setting});
				addClass();
			});
		}
	})
	function init() {
		// 基準値よりも少ないかのクラス名削除
		jQuery('.js-top-tech-slider.slick-slider').removeClass('is-few is-few-pcmin is-few-sp');
		filterBtn.each(function(index, element){
			jQuery(element).removeClass('active');
		});
		topTechSlider.slick('slickUnfilter');
		topTechSlider.slick('unslick', function(){setting});
		topTechSlider.slick(setting);
	}
}


/*もっと見るボタン
******************* */
function moreBtn() {
	if (document.querySelectorAll('.js-more-btn').length == 0) { return false; }
	const moreBtn = convertArray(document.querySelectorAll('.js-more-btn'));
	const moreBtnTarget = convertArray(document.querySelectorAll('.js-more-btn-target'));
	moreBtn.forEach(function(e, i) {
		e.addEventListener('click', function() {
			if(!isActive(e)) {
				e.classList.add('active');
				moreBtnTarget[i].classList.add('active');
			} else {
				e.classList.remove('active');
				moreBtnTarget[i].classList.remove('active');
			}
		});
	})
}


/*タブ切り替え
************** */
function changeTab(tab, tabContent) {
	if (document.querySelectorAll(tab).length == 0) { return false }

	const tabBtn = document.querySelectorAll(tab);
	const tabBtnArr = convertArray(tabBtn);
	const tabTarget = document.querySelectorAll(tabContent);
	const tabTargetArr = convertArray(tabTarget);

	tabBtnArr.forEach(function(e) {
		e.addEventListener('click', function() {
			// activeクラスすべて外す
			tabBtnArr.forEach(function(e) {
				e.classList.remove('active');
			});
			tabTargetArr.forEach(function(e) {
				e.classList.remove('active');
			});

			// クリックした要素と対応するコンテンツにactiveクラス付ける
			this.classList.add('active');
			const index = tabBtnArr.indexOf(this);
			tabTargetArr[index].classList.add('active');

		});
	});
}

/*　タブ切り替えニュースページ
************** */
function changeNewsTab() {
	if (document.querySelectorAll('.js-newstab li').length == 0) { return false }

	const tabBtnPc = document.querySelectorAll('.js-newstab li');
	const tabBtnPcArr = convertArray(tabBtnPc);
	const tabBtnSp = document.querySelector('.js-newstab-sp');
	// const tabBtnSpArr = convertArray(tabBtnSp);
	const tabTarget = document.querySelectorAll('.js-newstab-target');
	const tabTargetArr = convertArray(tabTarget);

	if(!mq.matches) {
		// SPの場合
		tabBtnSp.addEventListener('change', function(e) {
			tabTargetArr.forEach(function(e) {
				e.classList.remove('active');
			});
			// セレクトした要素と対応するコンテンツにactiveクラス付ける
			const index = tabBtnSp.selectedIndex;
			tabTargetArr[index].classList.add('active');

		})
	} else {
		// PCの場合
		tabBtnPcArr.forEach(function(e) {
			e.addEventListener('click', function() {
				// activeクラスすべて外す
				tabBtnPcArr.forEach(function(e) {
					e.classList.remove('active');
				});
				tabTargetArr.forEach(function(e) {
					e.classList.remove('active');
				});
				// クリックした要素と対応するコンテンツにactiveクラス付ける
				this.classList.add('active');
				const index = tabBtnPcArr.indexOf(this);
				tabTargetArr[index].classList.add('active');
			});
		});
	}
	
}

/*チェックボックス選択解除ボタン
******************* */
function checkedRefleshBtn() {
	if (document.querySelectorAll('.js-reflesh-btn').length == 0) { return false; }
	const refleshBtn = convertArray(document.querySelectorAll('.js-reflesh-btn'));
	const refleshBtnTarget = convertArray(document.querySelectorAll('.js-reflesh-target'));

	refleshBtn.forEach(function(e, i) {
		var refleshBtnTargetItem = convertArray(refleshBtnTarget[i].querySelectorAll('input[type=checkbox]'));
		var count = 0;

		refleshBtnTargetItem.forEach(function(checkbox) {
			checkbox.addEventListener('change', function() {
				if(this.checked) {
					count += 1;
				} else {
					count -= 1;
				}
				if(count >= 1) {
					refleshBtn[i].classList.add('active');
				} else {
					refleshBtn[i].classList.remove('active');
				}
			})
		})


		// 解除ボタンを押したとき解除
		e.addEventListener('click', function() {
			refleshBtnTargetItem.forEach(function(checkbox) {
				checkbox.checked = false;
				e.classList.remove('active');
			})
		});
	})
}

function openIndex() {
	if (document.querySelectorAll('.js-index-open').length == 0) { return false; }
	var openBtn = convertArray(document.querySelectorAll(".js-index-open"));
	var target = convertArray(document.querySelectorAll(".js-index-target"));

	if(!mq.matches) {
		openBtn.forEach(function(open, i) {
			target[i].style.display = 'none';
			jQuery(open).off('click');

			jQuery(open).on("click", function(event) {
				if(!isActive(open)) {
					$.when(
						open.classList.add('active'),
						jQuery(target[i]).stop().slideDown(),
					).done(function() {
						target[i].style.display = 'flex';
					});
				} else {
					$.when(
						open.classList.remove('active'),
						jQuery(target[i]).stop().slideUp(),
					).done(function() {
						target[i].style.display = 'none';
					});

				}
			});
		})
	} else {
		openBtn.forEach(function(open, i) {
			open.classList.remove('active');
			target[i].style.display = 'flex';
		})
	}
}


function openIndexLower() {
	if (document.querySelectorAll('.js-index-lower-open').length == 0) { return false; }
	var openBtn = document.querySelector(".js-index-lower-open");
	var target = document.querySelector(".js-index-lower-target");

	if(!mq.matches) {
		target.style.display = 'none';
		jQuery(openBtn).off('click');
		jQuery(openBtn).on("click", function(event) {
			if(!isActive(openBtn)) {
				$.when(
					openBtn.classList.add('active'),
					jQuery(target).stop().slideDown(),
				).done(function() {
					target.style.display = 'flex';
				});
			} else {
				$.when(
					openBtn.classList.remove('active'),
					jQuery(target).stop().slideUp(),
				).done(function() {
					target.style.display = 'none';
				});

			}
		});
	} else {
		openBtn.classList.remove('active');
		target.style.display = 'flex';
	}
}

function stickySideNav() {
	if (document.querySelectorAll('.js-sticky-nav').length == 0) { return false; }

	// ナビゲーションの追従
	if(!mq.matches) { //SPのとき
		// ナビゲーションの横幅調整
		navWrapWidth();
		function navWrapWidth() {
			setTimeout(function() {
				var navWrap = document.querySelector('.js-sticky-nav .p-products_detail__nav__list');
				var width = 80;
				var li = convertArray(navWrap.querySelectorAll('li'));
				
				li.forEach(function(e) {
					width = width + e.clientWidth;
				});
				navWrap.style.minWidth = width + 'px';
			}, 200);
		}

		// スクロールがナビゲーション位置に来たら追従
		var nav = $('.js-sticky-nav');
		var contants = $('.js-sticky-nav-endpos');
		var headerHeight = $('.l-header').innerHeight();
		var stickeyNav = document.querySelector('.js-sticky-nav');
		var wrapper = document.querySelector('.js-sticky-nav-endpos');
		var startPos = stickeyNav.getBoundingClientRect().top + window.scrollY;
		var endPos = wrapper.getBoundingClientRect().bottom + window.scrollY;
		var startPosNum = startPos - 70;
		var endPosNum = endPos - stickeyNav.clientHeight - 80;

		document.addEventListener("scroll", function () {
			var scroll = $(window).scrollTop();
			
			if (scroll >= startPosNum && scroll <= endPosNum){
				//スクロール領域に入った場合
				nav.addClass('is-fixed');
				nav.removeClass('is-fixed-end');
				nav.css('top', headerHeight + 'px');
				contants.css('padding-top','80px');
			} else if(scroll >= endPosNum) {
				//スクロール領域より下になった場合
				nav.addClass('is-fixed-end');
				contants.css('padding-top','0');
			} 
			else {
				//スクロール領域より上になった場合
				nav.removeClass('is-fixed');
				contants.css('padding-top','0');
			}
		});
	} else { //PCのとき
		// ナビゲーションの初期位置を製品タイトルの70px下に配置
		var baseHeight = $('.js-sticky-nav-base').innerHeight() + 70;
		var nav = $('.js-sticky-nav');
		nav.css('top', baseHeight + 'px');

		// スクロールがナビゲーション位置に来たら追従
		var stickeyNav = document.querySelector('.js-sticky-nav');
		var wrapper = document.querySelector('.js-sticky-nav-endpos');
		var startPos = stickeyNav.getBoundingClientRect().top + window.scrollY;
		// var endPos = wrapper.getBoundingClientRect().bottom + window.scrollY;
		var startPosNum = startPos - 70;
		var endPosNum = document.querySelector('body').getBoundingClientRect().bottom + window.scrollY - document.querySelector('.l-products').clientHeight - document.querySelector('.l-footer').clientHeight - stickeyNav.clientHeight - 140;
		var navBtmPos = document.querySelector('.p-products_detail__header').clientHeight + wrapper.clientHeight - stickeyNav.clientHeight - 110;

		document.addEventListener("scroll", function () {
			var headerHeight = $('.l-header').innerHeight() + 20;
			var scroll = $(window).scrollTop();

			if (scroll >= startPosNum && scroll <= endPosNum){
				//スクロール領域に入った場合
				nav.addClass('is-fixed');
				nav.removeClass('is-fixed-end');
				nav.css('top', headerHeight + 'px');
			} else if(scroll >= endPosNum) {
				//スクロール領域より下になった場合
				nav.addClass('is-fixed-end');
				nav.css('top', navBtmPos + 'px');
			} 
			else {
				//スクロール領域より上になった場合
				nav.removeClass('is-fixed');
				nav.css('top', baseHeight + 'px');
			}
		});
	}

	// ナビゲーション内のカレント
	var elemTop = [];
	//現在地を取得するための設定
	function PositionCheck(){
		//headerの高さを取得
		var headerH = $('.l-header').outerHeight(true);
		//.scroll-pointというクラス名がついたエリアの位置を取得する設定
		$(".scroll-point").each(function(i) {
			//.scroll-pointクラスがついたエリアからトップまでの距離を計算して設定
			// elemTop[i] = $(this).offset().top;
			elemTop[i] =Math.round(parseInt($(this).offset().top-headerH) - 150);//追従するheader分の高さを引き小数点を四捨五入
		});
	}
	//ナビゲーションに現在地のクラスをつけるための設定
	function ScrollAnime() {//スクロールした際のナビゲーションの関数にまとめる
		document.addEventListener("scroll", function () {
			var scroll = Math.round($(window).scrollTop());
			var NavElem = convertArray(document.querySelectorAll('.js-sticky-nav .p-products_detail__nav__item__link'));//ナビゲーションのliの何番目かを定義するための準備
			$(".js-sticky-nav .p-products_detail__nav__item__link").removeClass('current');//全てのナビゲーションの現在地クラスを除去

			var lastElm = elemTop.length - 1;
			for (var index = 0; index < elemTop.length; index++) {
				// 最初の要素の判定
				if (index == 0) {
					if(scroll >= 0 && scroll < elemTop[index + 1]) {
						NavElem[index].classList.add('current');
					}
				} 
				// 最後の要素の判定
				else if (index == lastElm) {
					if(scroll >= elemTop[lastElm]) {
						NavElem[NavElem.length - 1].classList.add('current');
					}
				} 
				// それ以外（途中の要素）の判定
				else {
					if(scroll >= elemTop[index] && scroll < elemTop[index + 1]) {
						NavElem[index].classList.add('current');
					}
				}
			}	
		});
	}
	PositionCheck();
	ScrollAnime();
}

function openAccordion() {
	if (document.querySelectorAll('.js-accordion-ttl').length == 0) { return false; }
	var openBtn = convertArray(document.querySelectorAll(".js-accordion-ttl"));

	openBtn.forEach(function(e) {
		e.addEventListener('click', function() {
			var wrap = e.closest('.js-accordion-wrap');
			var content = wrap.querySelector('.js-accordion-content');

			if(!isActive(wrap)) {
				wrap.classList.add('active');
				jQuery(content).slideDown();
			} else {
				wrap.classList.remove('active');
				jQuery(content).slideUp();
			}
		})
	});
}

function confirmModal(_elem) {
	if (document.querySelectorAll('.js-confirm-modal').length == 0) { return false; }
	const modal = document.querySelector('.js-confirm-modal');
	const modalContents = document.querySelector('.js-confirm-modal .m-modal__contents');
	const modalOpen = (_elem)? convertArray(document.querySelector(_elem).querySelectorAll('.js-confirm-modal-open')): convertArray(document.querySelectorAll('.js-confirm-modal-open'));
	const closeArr = convertArray(document.querySelectorAll('.js-confirm-modal-close'));
	const modalLink = document.querySelector('.js-modal-link');
	const body = document.body;

	// クッキーがない場合だけモーダル開く
	if($.cookie("disappear") !== "true") {
		modalOpen.forEach(function (item, i) {
			modalOpen[i].addEventListener("click", function (e) {
				e.preventDefault();
				modalCloseOffset = window.pageYOffset;
				modal.classList.add("active");
				body.classList.add("is-noscroll-ip");
				body.style.top = (- modalCloseOffset) + 'px';
	
				var href = item.getAttribute('href');
				modalLink.href = href;
	
				modalLink.addEventListener("click", function(e) {
					$.cookie("disappear", "true", { path: "/"});
					modalClose();
				});
			});
		});
		modalContents.addEventListener("click", function (e) {
			e.stopPropagation();
		});
		closeArr.forEach(function (item, i) {
			closeArr[i].addEventListener("click", function (e) {
				e.preventDefault();
				modalClose();
			});
		});
	
		if(mq.matches && !_elem) {
			modalClose()
		}
	
		function modalClose() {
			modal.classList.remove("active");
			body.classList.remove("is-noscroll-ip");
			body.style.top = '';
			window.scrollTo(0,modalCloseOffset);
		}
	} 
}

function selectbox() {
	if (document.querySelectorAll(".js-selectbox").length == 0) { return false }
	const target = document.querySelectorAll(".js-selectbox");
	const targetArr = convertArray(target);

	targetArr.forEach(function(e) {
		e.addEventListener('change', function() {
			if(e.value == "") {
				e.classList.add('unselected');
			} else {
				e.classList.remove('unselected');
			}
		});
	});
}

function sdsForm() {
	if (document.querySelectorAll(".p-sds_form .m-form").length == 0) { return false }
	const required = document.querySelectorAll(".m-form--required input, .m-form--required select");
	var requiredCount = document.querySelectorAll(".m-form--required input, .m-form--required select").length;
	var submitBtn = document.querySelector('.js-sds-form-submit');

	required.forEach(function(e) {
		e.addEventListener('change', function() {
			// 必須項目空白だったらエラー出す
			var parent = e.closest(".m-form--required");
			if(e.value == "") {
				parent.classList.add('m-form__error');
				requiredCount += 1;
			} else {
				parent.classList.remove('m-form__error');
				if(requiredCount >= 1) {
					requiredCount -= 1;
				}
			}

			// 必須項目全部埋めたらボタン活性化
			var error = document.querySelectorAll(".m-form__error").length;
			if (error === 0 && requiredCount === 0) {
				submitBtn.classList.remove('m-btn--disabled');
			} else {
				submitBtn.classList.add('m-btn--disabled');
			}
		});
	});

}

function countUpNum() {
	if (document.querySelectorAll(".js-count").length == 0) { return false }
	const target = document.querySelectorAll(".js-count");
	const targetArr = convertArray(target);

	targetArr.forEach(function(e) {
		let once = false;
		let time = 2000; 
		window.addEventListener('scroll', function () {
			// ターゲットの画面トップからの距離
			taeget_position = e.getBoundingClientRect().top;
			// 画面トップからの距離から画面の高さより小さければ実行する
				if (taeget_position <= window.innerHeight && once !== true) {
					once = true;
					if($(e).hasClass('js-dot')) {
						let num = Number(e.textContent).toLocaleString();
						setTimeout(function() {
							e.textContent = num;
						}, time + 50);
					}
					if($(e).hasClass('js-per')) {
						$(e).prop('Counter',0).animate({//0からカウントアップ
							Counter: $(e).text(),
						}, {
							// スピードやアニメーションの設定
							duration: time,
							easing: 'swing',//動きの種類。他にもlinearなど設定可能
							step: function (now) {
								$(e).text(Math.floor(now * 100) / 100);
							}
						});
					} else {
						$(e).prop('Counter',0).animate({//0からカウントアップ
							Counter: $(e).text(),
						}, {
							// スピードやアニメーションの設定
							duration: time,
							easing: 'swing',//動きの種類。他にもlinearなど設定可能
							step: function (now) {
								$(e).text(Math.ceil(now));
							}
						});
					}
				}
		});
	});
}

function pinMap() {
	if (document.querySelectorAll(".p-corporate-globalnetwork__pin__wrap").length == 0) { return false }
	const map = document.querySelector(".p-corporate-globalnetwork__pin__wrap");
	const pin = document.querySelectorAll(".p-corporate-globalnetwork__pin");
	const pinArr = convertArray(pin);
	let once = false;
	let time = 0.08;//遅延時間を増やす秒数の値
	let value = time;

	window.addEventListener('scroll', function () {
		taeget_position = map.getBoundingClientRect().top;
		// 画面トップからの距離から画面の高さより小さければ実行する	
		if (taeget_position <= window.innerHeight && once !== true) {
			once = true;
			pinArr.forEach(function(e, i) {				
				let num = i + 1;
				if(num < 4) {
					value = value + 0.25;
					e.style.animationDuration = "0.2s";
				} else  if (num >= 4 && num <= 6) {
					value = value + 0.15;
					e.style.animationDuration = "0.15s";
				} else {
					e.style.animationDuration = "0.1s";	
				}
				e.style.animationDelay = value + "s";
				e.classList.add('p-corporate-globalnetwork__pin__anime--' + num);
				value = value + time;
			});
		}
	});
}


function gallery() {
	if (document.querySelectorAll(".js-gallery").length == 0) { return false }
	const gallery = document.querySelectorAll(".js-gallery");
	gallery.forEach(function(gal) {
		let main = gal.querySelector('.m-gallery__main img');
		let movie = gal.querySelector('.m-gallery__main iframe');
		let sub = gal.querySelectorAll('.m-gallery__sub__item');
		if(sub[0]){
			let thumb=sub[0].querySelector('img');
			if(thumb){
				main.setAttribute('src',thumb.getAttribute('src'));
				if(movie){
					$(movie).hide();
				}
			}
			else{
				thumb=sub[0].querySelector('iframe');
				movie.setAttribute('src',thumb.getAttribute('src'));
				$(main).hide();
			}
		}

		sub.forEach(function(tar) {
			tar.addEventListener('click', function() {
				let image = this.querySelector('img');
				let youtube=this.querySelector('iframe');
				if(image){
					let src = image.getAttribute('src');
					let alt = image.getAttribute('alt');
					$(main).fadeOut("fast", function() {
						main.setAttribute('src', src);
						main.setAttribute('alt', alt);
						if(movie){
							$(movie).fadeOut();
						}
						$(main).fadeIn();

					})
				}
				if(youtube){
					let src = youtube.getAttribute('src');
					movie.setAttribute('src',src);
					$(main).fadeOut();
					$(movie).fadeIn();
				}

			})
		});
	});
}

function SlideToggle() {
	if (document.querySelectorAll(".js-slide-toggle").length == 0) { return false }
	var slide = convertArray(document.querySelectorAll(".js-slide-toggle"));
	var block = convertArray(document.querySelectorAll(".js-slide-toggleBlock"));
	slide.forEach(function(e, i) {
		var tar = block[i];
		
		$(e).click(function() {
			$(e).toggleClass('is-active');
			$(tar).slideToggle();
		})
	})
}

function productMainvPadding() {
	if (document.querySelectorAll(".js-product-mainv").length == 0) { return false }

	var breadcrumb = document.querySelector('.js-product-mainv + .l-breadcrumb');
	if(mq.matches) { //pcのとき
		breadcrumb.style.marginBottom = `-${breadcrumb.clientHeight}px`;
	} else {
		breadcrumb.style.marginBottom = '0px';	
	}
}

function setModal() {
  if ($(window).width() > 767) {
    $('.js-modal').on('click', function() {
      const modalHTML = `
        <div class="modal-overlay" role="dialog" aria-modal="true">
          <div class="modal">
            <div class="modal-content">
              ${$(this).find('.js-modal__item').prop('outerHTML')}
            </div>
          </div>
        </div>
      `;

      $('body').append(modalHTML);

      const $overlay = $('.modal-overlay');
      const $modal = $overlay.find('.modal');

      setTimeout(() => {
        $overlay.addClass('show');
        $modal.addClass('show');
      }, 0);

      // Escキーで閉じる
      $(document).on('keydown', function(event) {
        if (event.key === 'Escape') {
          $overlay.removeClass('show');
          $modal.removeClass('show');
          setTimeout(() => {
            $overlay.remove();
          }, 400);
        }
      });

      // オーバーレイをクリックしたときにモーダルを閉じる
      $overlay.on('click', function(event) {
        // クリックしたのがオーバーレイ自身かどうかを確認
        if (event.target === $overlay[0] || event.target === $modal[0]) {
          $overlay.removeClass('show');
          $modal.removeClass('show');
          setTimeout(() => {
            $overlay.remove();
          }, 400);
        }
      });
    });
  }
}

function openContents() {
  const openBtns = document.querySelectorAll(".js-contents-ttl");

  openBtns.forEach(btn => {
    btn.addEventListener('click', () => {
      const wrap = btn.closest('.js-contents-wrap');
      const content = wrap.querySelector('.js-contents-content');
      const openCloseSpan = btn.querySelector('.open-close');

      wrap.classList.toggle('active');
      content.style.display = wrap.classList.contains('active') ? 'block' : 'none';
      openCloseSpan.textContent = wrap.classList.contains('active') ? '非表示' : '表示';
    });
  });
}
